'use client';

import { useToasts } from '@mnd-frontend/ui';
import { usePathname } from 'next/navigation';
import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CACHE_TTL } from '../../../public/constants';
import {
  CustomerCase,
  Event,
  PostItem,
  PostSection,
  Resource,
} from '../../components/Archive/PostTypeComponents';
import ArchivePageSearch from '../../components/ArchivePageSearch/ArchivePageSearch';
import { getButtonStyling } from '../../components/Button/styling';
import {
  chooseLightOrDarkTextColor,
  moduleBackgroundToCSS,
} from '../../components/modules/getModuleColors';
import { ctaClickHandler, track } from '../../lib/tracker';
import logger from '../../utils/logger';
import { ArchivePageClientQuery, ArchivePageQuery, PostTypeEnum } from '../../wp-generated/types';
import { BreadcrumbsComponent } from '../Breadcrumbs';

const Header = styled.header<{ $bgColor?: string | null }>`
  margin-bottom: calc(var(--section-padding) * -1);
  padding: var(--section-padding, 6rem) var(--site-padding);
  padding-bottom: calc(var(--section-padding) + var(--spacing-large));
  background-color: ${({ $bgColor }) => moduleBackgroundToCSS($bgColor)};
  color: ${({ $bgColor }) => chooseLightOrDarkTextColor($bgColor)};
  font-size: var(--font-size-large);
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    text-align: center;
    color: ${({ $bgColor }) => $bgColor && chooseLightOrDarkTextColor($bgColor)};
    font-size: var(--h1-size, 3rem);
    font-style: var(--heading-font-style, normal);
    font-weight: var(--heading-font-weight, bold);
    letter-spacing: -0.05em;
  }
`;

const Description = styled.div`
  text-align: center;
  max-width: 52ch;
  text-align: center;
`;

const Button = styled.button`
  ${getButtonStyling({ $variant: 'primary', $fullWidth: false })}
`;

const Message = styled.div<{ $color: string }>`
  text-align: center;
  font-size: calc(1.5rem + 0.3vw);
  color: ${({ $color }) => $color};
  padding-bottom: 3rem;
`;

const LoadMoreWrapper = styled.div`
  display: grid;
  place-items: center;
  margin-bottom: 3rem;
`;

type ArchiveProps = {
  page: NonNullable<ArchivePageQuery['archivePage']>;
  currentPage?: number;
  uri: string;
  initialCanLoadMore: boolean;
  postsPerPage: number;
};

/**
 * @see ../../../../../components/Archive/index.tsx
 * @description copy/paste and updated hooks
 */

const archiveTypeFallbackTitleKey = {
  [PostTypeEnum.CustomerCase]: 'marketing_breadcrumb_customer_case',
  [PostTypeEnum.Post]: 'marketing_breadcrumb_blog',
  [PostTypeEnum.Event]: 'publish_pr-edit_related-material_filter-events',
  [PostTypeEnum.Resource]: null,
} as const;

const Archive = ({
  page,
  currentPage = 1,
  uri,
  initialCanLoadMore,
  postsPerPage,
}: ArchiveProps) => {
  const pathname = usePathname();
  const { t } = useTranslation();
  const categories =
    page.postType === PostTypeEnum.Resource
      ? page.backlinkToRootQuery?.resourceCategories?.nodes ?? []
      : page.postType === PostTypeEnum.CustomerCase
        ? page.backlinkToRootQuery?.customerCaseCategories?.nodes ?? []
        : page.postType === PostTypeEnum.Post
          ? page.backlinkToRootQuery?.categories?.nodes ?? []
          : [];
  const [clientSidePosts, setClientSidePosts] = useState<typeof page.posts | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(initialCanLoadMore);
  const [query, setQuery] = useState('');
  const [currentPageState, setCurrentPageState] = useState(currentPage);
  const { showFailureToast } = useToasts();

  const postsToRender = clientSidePosts === null ? page.posts?.filter(Boolean) : clientSidePosts;

  const fetcher = async ({ uri, search, page }: { uri: string; search: string; page: number }) => {
    const url = `/api/archive-page/?uri=${encodeURIComponent(uri)}&search=${encodeURIComponent(search)}&page=${page}`;
    try {
      const response = await fetch(url, {
        next: {
          revalidate: CACHE_TTL,
        },
      });
      const responseJson = (await response.json()) as ArchivePageClientQuery;
      const results = responseJson.archivePage?.posts?.filter(Boolean) ?? [];

      return results;
    } catch (error) {
      showFailureToast();
      logger.error('Error fetching data:', error);
      return [];
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  useLayoutEffect(() => {
    const urlQuery = new URLSearchParams(window.location.search).get('s');
    if (urlQuery) {
      setQuery(urlQuery);
    }
  }, []);

  const handleLoadMore = async () => {
    let fetchUri = uri;
    const categorySlug = new URLSearchParams(window.location.search).get('categorySlug');

    setLoadingMore(true);
    if (categorySlug) {
      if (pathname.includes('resource-categories')) {
        fetchUri = uri;
      } else {
        fetchUri = `${uri}category/${categorySlug}/`;
      }
      if (
        pathname.includes('resource-categories') ||
        pathname.includes('customer-case-categories')
      ) {
        window.history.pushState({}, '', `${uri}page/${currentPageState + 1}/`);
      } else {
        window.history.pushState(
          {},
          '',
          `${uri}category/${categorySlug}/page/${currentPageState + 1}/`,
        );
      }
    } else {
      window.history.pushState({}, '', `${uri}page/${currentPageState + 1}/`);
    }

    try {
      const posts = await fetcher({ uri: fetchUri, search: query, page: currentPageState + 1 });
      setClientSidePosts([...(page?.posts?.filter(Boolean) ?? []), ...posts]);
      setCurrentPageState(prev => prev + 1);
      if (posts.length <= postsPerPage) {
        setCanLoadMore(false);
      }
    } catch (e) {
      console.error(e);
    }
    setLoadingMore(false);
  };

  const handleSiteSearchClick = (
    type: 'blog_post' | 'event' | 'resource' | 'customer_case',
    navigation_selection: string | null,
    id: string,
  ) => {
    const submittedQuery = new URLSearchParams(window.location.search).get('s');
    if (!navigation_selection) return;
    if (submittedQuery) {
      track({
        event: 'site_search_result_click',
        traits: {
          event_info: {
            navigation_selection,
            search_term: submittedQuery,
            user_interaction: `${type}_click`,
          },
        },
      });
    } else {
      track({
        event: 'select_content',
        traits: {
          event_info: {
            content_id: id,
            content_type: type,
            page_type: 'archive',
          },
        },
      });
    }
  };

  const handleFilterInteractionClick = (categoryName?: string | null) => {
    if (!categoryName) return;
    track({
      event: 'filter_interaction',
      traits: {
        event_info: {
          filter_name: categoryName,
          filter_type: 'tab',
          navigation_selection: categoryName,
          user_interaction: 'filter_interaction',
        },
      },
    });
  };

  const fallbackTitleKey = page.postType && archiveTypeFallbackTitleKey[page.postType];
  const fallbackTitle = fallbackTitleKey ? t(fallbackTitleKey) : '';

  return (
    <>
      {(page?.breadcrumbs?.length || 0) > 1 && (
        <BreadcrumbsComponent
          breadcrumbs={
            (page?.breadcrumbs?.filter(b => b?.url && b?.text) as {
              url: string;
              text: string;
            }[]) ?? []
          }
          background={
            page.postType === 'post' || page.postType === 'resource' ? 'red' : 'var(--color-orange)'
          }
        />
      )}
      <Header $bgColor={page.bgColor}>
        <h1>{page.title || fallbackTitle}</h1>
        <Description dangerouslySetInnerHTML={{ __html: page?.description || '' }} />
        {page.postType !== PostTypeEnum.Event && (
          <ArchivePageSearch
            onClick={handleFilterInteractionClick}
            pageBackground={page.bgColor}
            loading={false}
            search={query}
            onSubmit={async e => {
              e.preventDefault();
              /**
               * @see https://github.com/vercel/next.js/discussions/48110
               * @see https://nextjs.org/docs/app/building-your-application/routing/linking-and-navigating#using-the-native-history-api
               */
              window.history.pushState({}, '', `${uri}page/1/?s=${query}`);
              setLoading(true);
              const posts = await fetcher({ uri, search: query, page: 1 });
              setClientSidePosts(posts);

              track({
                event: 'search',
                traits: {
                  event_info: {
                    user_interaction: 'search',
                    navigation_selection: 'search',
                    search_term: query,
                    search_results: posts.length.toString(),
                  },
                },
              });
              setLoading(false);
            }}
            onChange={handleOnChange}
            setSearch={setQuery}
            categories={categories}
            pageUri={page.pageUri}
          />
        )}
      </Header>
      {loading ? (
        <Message $color={chooseLightOrDarkTextColor(page.bgColor)}>{t('common_loading')}</Message>
      ) : (postsToRender?.length ?? 0) === 0 ? (
        <Message $color={chooseLightOrDarkTextColor(page.bgColor)}>
          {t('common_no-search-results')}
        </Message>
      ) : (
        <>
          <PostSection>
            <ul>
              {postsToRender &&
                postsToRender.map((post, index) => (
                  <li key={index}>
                    {post && post.__typename === 'CustomerCase' && (
                      <CustomerCase
                        post={post}
                        readMoreTranslation={t('common_read-more')}
                        onClick={title => handleSiteSearchClick('customer_case', title, post.id)}
                      />
                    )}
                    {post && post.__typename === 'Event' && (
                      <Event
                        post={post}
                        readMoreTranslation={t('common_read-more')}
                        onClick={title => handleSiteSearchClick('event', title, post.id)}
                      />
                    )}
                    {post && post.__typename === 'Post' && (
                      <PostItem
                        post={post}
                        readMoreTranslation={t('common_read-more')}
                        onClick={title => handleSiteSearchClick('blog_post', title, post.id)}
                      />
                    )}
                    {post && post.__typename === 'Resource' && (
                      <Resource
                        post={post}
                        readMoreTranslation={t('common_read-more')}
                        onClick={title => handleSiteSearchClick('resource', title, post.id)}
                      />
                    )}
                  </li>
                ))}
            </ul>
          </PostSection>
          {canLoadMore && (
            <LoadMoreWrapper>
              <Button
                type="button"
                onClick={e => {
                  ctaClickHandler('end_of_archive')(e);
                  handleLoadMore();
                }}
                disabled={loadingMore}
              >
                {loadingMore ? t('common_loading') : t('monitor_insights_media-feed_load-more')}
              </Button>
            </LoadMoreWrapper>
          )}
        </>
      )}
    </>
  );
};

export default Archive;
