'use client';

import { isoDate } from '@mnd-frontend/dates';
import styled from 'styled-components';
import {
  CustomerCaseListFragment,
  EventListFragment,
  PostListFragment,
  ResourceListFragment,
} from '../../wp-generated/types';
import PostImage from '../PostImage';
import { PostTag } from '../PostTag';

export const PostSection = styled.section`
  width: var(--site-width);
  margin: 0 auto 6rem auto;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    gap: var(--spacing-medium);
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    @media (min-width: 768px) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    li {
      padding: 0;
      margin: 0 0 1rem 0;
      @media (min-width: 768px) {
        &:nth-child(-n + 2) {
          grid-column: span 6;
        }
        grid-column: span 4;
      }
    }
    .read-more {
      display: block;
      width: 100%;
      color: var(--link-color);
      font-size: var(--font-size-large);
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const PostStyle = styled.article`
  h2 {
    margin: 0 0 var(--spacing-small) 0;
    color: var(--text-color);
    letter-spacing: -0.02em;
    line-height: 1.2;
    font-size: calc(1rem + 0.3vw);
    @media (min-width: 1080px) {
      font-size: calc(1.5rem + 0.3vw);
    }
    font-weight: bold;
    word-break: break-word;
  }
  img {
    max-width: 100%;
    transition: transform 0.5s var(--easing);
    &:hover {
      transform: scale(1.2);
    }
  }

  time {
    display: block;
    font-weight: 700;
    margin-bottom: var(--spacing-medium);
  }
`;

const PostTags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: var(--spacing-small) 0;
`;

export const CustomerCase = ({
  post,
  readMoreTranslation,
  onClick,
}: {
  post: CustomerCaseListFragment;
  readMoreTranslation: string;
  onClick: (title: string | null) => void;
}) => {
  return (
    <PostStyle>
      <a
        href={post.link || '#'}
        aria-label={post.title ?? undefined}
        onClick={() => onClick(post.title + '(image)')}
      >
        <PostImage
          data={post.featuredImage?.node}
          aspectRatio={{ mobile: '4 / 3', desktop: '4 / 3' }}
        />
      </a>
      <h2>
        <a href={post.link || '#'} onClick={() => onClick(post.title)}>
          {post.title}
        </a>
      </h2>
      <div dangerouslySetInnerHTML={{ __html: post.excerpt || '' }} />
      <a className="read-more" href={post.link || '#'} onClick={() => onClick(readMoreTranslation)}>
        {readMoreTranslation}
      </a>
    </PostStyle>
  );
};

export const Resource = ({
  post,
  readMoreTranslation,
  onClick,
}: {
  post: ResourceListFragment;
  readMoreTranslation: string;
  onClick: (title: string | null) => void;
}) => {
  return (
    <PostStyle>
      <a
        href={post.resourceInformation?.redirectUrl || '#'}
        aria-label={post.title ?? undefined}
        onClick={() => onClick(post.title + '(image)')}
      >
        <PostImage data={post.featuredImage?.node} />
      </a>
      {post?.resourceCategories && post.resourceCategories.nodes.length > 0 && (
        <PostTags>
          {post.resourceCategories.nodes.map((category, i) => (
            <PostTag key={i} href={category?.link || ''}>
              {category?.name}
            </PostTag>
          ))}
        </PostTags>
      )}
      <h2>
        <a href={post.resourceInformation?.redirectUrl || '#'} onClick={() => onClick(post.title)}>
          {post.title}
        </a>
      </h2>
      <div dangerouslySetInnerHTML={{ __html: post.excerpt || '' }} />
      <a
        className="read-more"
        href={post.resourceInformation?.redirectUrl || '#'}
        onClick={() => onClick(readMoreTranslation)}
      >
        {readMoreTranslation}
      </a>
    </PostStyle>
  );
};

export const Event = ({
  post,
  readMoreTranslation,
  onClick,
}: {
  post: EventListFragment;
  readMoreTranslation: string;
  onClick: (title: string | null) => void;
}) => {
  return (
    <PostStyle>
      <a
        href={post.eventInformation?.redirectUrl || '#'}
        aria-label={post.title ?? undefined}
        onClick={() => onClick(post.title + '(image)')}
      >
        <PostImage data={post.featuredImage?.node} />
      </a>
      <h2>
        <a href={post.eventInformation?.redirectUrl || '#'} onClick={() => onClick(post.title)}>
          {post.title}
        </a>
      </h2>
      <div dangerouslySetInnerHTML={{ __html: post.excerpt || '' }} />
      <a
        className="read-more"
        href={post.eventInformation?.redirectUrl || '#'}
        onClick={() => onClick(readMoreTranslation)}
      >
        {readMoreTranslation}
      </a>
    </PostStyle>
  );
};

export const PostItem = ({
  post,
  readMoreTranslation,
  onClick,
}: {
  post: PostListFragment;
  readMoreTranslation: string;
  onClick: (title: string | null) => void;
}) => {
  return (
    <PostStyle>
      <a
        href={post.link || '#'}
        aria-label={post.title ?? undefined}
        onClick={() => onClick(post.title + '(image)')}
      >
        <PostImage data={post.featuredImage?.node} />
      </a>
      {post.categories && post.categories.nodes.length > 0 && (
        <PostTags>
          {post.categories.nodes.map((category, i) => (
            <PostTag key={i} href={category?.link || ''}>
              {category?.name}
            </PostTag>
          ))}
        </PostTags>
      )}
      <h2>
        <a href={post.link || '#'} onClick={() => onClick(post.title)}>
          {post.title}
        </a>
      </h2>
      {post.date && <time dateTime={post.date}>{isoDate(post.date)}</time>}
      <a className="read-more" href={post.link || '#'} onClick={() => onClick(readMoreTranslation)}>
        {readMoreTranslation}
      </a>
    </PostStyle>
  );
};
